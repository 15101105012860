<template>
  <v-container fluid class="container-main">
    <v-row justify="center">
      <v-col cols="12" sm="11">
        <h2 class="text-title">
          {{ t(`$vuetify.service.green_team.title`) }}
        </h2>
        <v-row justify="center" class="my-6">
          <v-col cols="12" sm="10" md="8">
            <h4 class="text-subtitle fontSFProR">
              {{ t(`$vuetify.service.green_team.description`) }}
            </h4>
          </v-col>
        </v-row>

        <v-row justify="center" class="mt-12">

          <v-col cols="11" sm="3" class="pr-sm-0">
            <v-row :justify="$vuetify.display.xs ? 'space-between' : 'end'">
              <v-col cols="6" class="pa-sm-0" :class="$vuetify.display.xs ? 'pb-10' : ''">
                <v-img src="../assets/img/GreenTeam/1.jpg"></v-img>
              </v-col>
              <v-col v-if="$vuetify.display.xs" cols="4" class="pa-sm-0" alignSelf="end">
                <v-img src="../assets/img/GreenTeam/2.jpg"></v-img>
              </v-col>
            </v-row>
            <v-row v-if="$vuetify.display.smAndUp" justify="start" class="mt-15">
              <v-col cols="11">
                <v-img src="../assets/img/GreenTeam/3.jpg"></v-img>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="6" md="5" class="pa-0" alignSelf="center">
            <v-row justify="center" class="my-4">
              <v-col cols="3" sm="3" md="3" lg="2">
                <svg width="100%" viewBox="0 0 87 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g class="svg-animation">
                    <path
                      d="M44.1355 5.10485C42.6957 5.10485 41.7996 4.12696 41.7996 2.53362V2.5302C41.7996 0.964211 42.6957 0 44.1184 0C45.2881 0 46.0473 0.676999 46.2354 1.5865L46.2491 1.64121H45.353L45.3428 1.6036C45.1547 1.07362 44.7272 0.765898 44.1218 0.765898C43.2326 0.765898 42.6991 1.41554 42.6991 2.52336V2.52678C42.6991 3.65853 43.2565 4.33895 44.1458 4.33895C44.8913 4.33895 45.4044 3.88078 45.4214 3.1901L45.4249 3.11488H44.2005V2.42762H46.2867V2.96102C46.2867 4.30134 45.4864 5.10485 44.1355 5.10485Z"
                      fill="#101010" />
                    <path
                      d="M48.0378 5.16537L48.7564 0.284059L50.7053 0.570812C51.7475 0.724145 52.2984 1.39619 52.1595 2.33998L52.1585 2.34674C52.0619 3.00299 51.6318 3.48231 51.033 3.60502L51.8105 5.72045L50.8157 5.57409L50.1237 3.60602L49.1797 3.46712L48.9108 5.29381L48.0378 5.16537ZM49.2812 2.77704L50.2659 2.92191C50.8174 3.00306 51.1871 2.7464 51.2642 2.22208L51.2652 2.21531C51.3404 1.70452 51.0411 1.34253 50.493 1.26188L49.5253 1.1195L49.2812 2.77704Z"
                      fill="#101010" />
                    <path
                      d="M53.8123 6.19283L55.2341 1.46814L58.293 2.3882L58.0792 3.0987L55.8652 2.43279L55.482 3.70646L57.5747 4.33592L57.3688 5.02023L55.276 4.39077L54.8711 5.73647L57.085 6.40238L56.8712 7.11289L53.8123 6.19283Z"
                      fill="#101010" />
                    <path
                      d="M59.178 7.99051L61.2728 3.52313L64.1651 4.87865L63.8501 5.55046L61.7567 4.56938L61.192 5.77368L63.1708 6.70106L62.8674 7.34811L60.8886 6.42072L60.292 7.69314L62.3853 8.67422L62.0703 9.34603L59.178 7.99051Z"
                      fill="#101010" />
                    <path
                      d="M63.7094 10.2092L66.4325 6.09433L67.0942 6.53202L67.1916 10.7413L67.2087 10.7526L69.1165 7.86962L69.8467 8.35258L67.1236 12.4674L66.4676 12.0335L66.3721 7.8091L66.3578 7.79967L64.4425 10.694L63.7094 10.2092Z"
                      fill="#101010" />
                    <path
                      d="M68.5428 13.5464L70.8504 8.98879L71.533 9.60028L69.687 12.9461L69.6997 12.9575L72.9212 10.8438L73.5173 11.3777L71.768 14.8103L71.7807 14.8217L74.9055 12.6212L75.5881 13.2327L71.309 16.0243L70.6748 15.4562L72.2957 12.1566L72.2804 12.1429L69.1771 14.1146L68.5428 13.5464Z"
                      fill="#101010" />
                    <path
                      d="M73.8709 18.9731C72.9476 17.864 73.1267 16.5451 74.3334 15.5412L74.3386 15.5368C75.5426 14.5351 76.8769 14.6038 77.7957 15.7076C78.7167 16.8141 78.5376 18.133 77.3336 19.1347L77.3284 19.1391C76.1217 20.143 74.7941 20.0821 73.8709 18.9731ZM74.4597 18.4831C75.0307 19.1691 75.9192 19.146 76.7552 18.4505L76.7604 18.4462C77.6017 17.7463 77.7713 16.8756 77.2068 16.1976C76.6446 15.5221 75.7579 15.5259 74.914 16.228L74.9087 16.2324C74.0649 16.9345 73.8953 17.8051 74.4597 18.4831Z"
                      fill="#101010" />
                    <path
                      d="M76.3161 22.0052L80.5288 19.435L81.555 21.1161C82.1037 22.015 81.9395 22.8683 81.125 23.3653L81.1191 23.3688C80.5528 23.7144 79.9092 23.6905 79.4318 23.3088L78.3027 25.2595L77.7789 24.4014L78.8493 22.6107L78.3523 21.7964L76.7758 22.7582L76.3161 22.0052ZM78.9478 21.433L79.4663 22.2823C79.7567 22.7581 80.1905 22.878 80.643 22.6019L80.6488 22.5983C81.0896 22.3293 81.1765 21.8678 80.8878 21.395L80.3783 20.5603L78.9478 21.433Z"
                      fill="#101010" />
                    <path
                      d="M79.2197 27.0997L83.7617 25.17L84.1068 25.9819L80.2478 27.6214L81.1239 29.6826L80.4409 29.9728L79.2197 27.0997Z"
                      fill="#101010" />
                    <path
                      d="M81.1659 31.9746L85.9406 30.7266L86.3964 32.4699C86.7684 33.8924 86.1296 34.9641 84.6207 35.3584L84.6141 35.3602C83.102 35.7554 81.9955 35.1469 81.6218 33.7179L81.1659 31.9746ZM82.1104 32.6395L82.3197 33.44C82.5706 34.3993 83.2947 34.7756 84.3833 34.491L84.3899 34.4893C85.4554 34.2108 85.9 33.5185 85.6518 32.5691L85.4424 31.7686L82.1104 32.6395Z"
                      fill="#101010" />
                    <path d="M84.5827 43.9896L85.3415 44.0174L85.2609 46.2213L84.5022 46.1935L84.5827 43.9896Z"
                      fill="#101010" />
                    <path
                      d="M81.4577 54.5077L86.1285 56.1007L85.5145 57.9C85.1921 58.8449 84.3476 59.2758 83.4122 58.9567L83.4057 58.9545C82.4735 58.6366 82.0652 57.7784 82.3877 56.8335L82.7168 55.8692L81.1728 55.3426L81.4577 54.5077ZM84.9022 57.4599L85.1606 56.7027L83.39 56.0988L83.1316 56.856C82.9273 57.4547 83.1318 57.9146 83.6918 58.1056L83.6982 58.1078C84.2582 58.2988 84.6979 58.0586 84.9022 57.4599Z"
                      fill="#101010" />
                    <path
                      d="M79.7431 59.3274L83.3252 63.1525L82.8717 64.0343L77.6757 63.3474L78.0995 62.5233L79.4248 62.7355L80.2646 61.1026L79.3208 60.1484L79.7431 59.3274ZM82.3214 63.1825L80.7682 61.6113L80.1317 62.849L82.312 63.2007L82.3214 63.1825Z"
                      fill="#101010" />
                    <path
                      d="M76.7752 64.7791L80.7889 67.6499L79.6427 69.2516C79.0297 70.1081 78.18 70.2907 77.4039 69.7356L77.3984 69.7317C76.8588 69.3457 76.629 68.7441 76.7937 68.1556L74.5563 67.8796L75.1414 67.0621L77.2086 67.3467L77.7638 66.5708L76.2618 65.4965L76.7752 64.7791ZM78.3312 66.9767L77.7521 67.7859C77.4277 68.2392 77.487 68.6852 77.9182 68.9935L77.9237 68.9975C78.3438 69.2979 78.8026 69.1973 79.125 68.7468L79.6941 67.9515L78.3312 66.9767Z"
                      fill="#101010" />
                    <path
                      d="M72.5258 70.1639L75.5443 73.0734L76.5698 72.0101L77.1041 72.525L74.4455 75.2819L73.9112 74.7669L74.9343 73.706L71.9157 70.7965L72.5258 70.1639Z"
                      fill="#101010" />
                    <path
                      d="M69.6573 73.0146L72.6735 76.9197L72.0455 77.4045L68.0293 76.1376L68.013 76.1501L70.1263 78.8861L69.4333 79.4211L66.4171 75.5159L67.0396 75.0353L71.0697 76.3089L71.0832 76.2985L68.9616 73.5516L69.6573 73.0146Z"
                      fill="#101010" />
                    <path
                      d="M64.5837 76.709L66.999 81.0116L64.2134 82.5746L63.8502 81.9276L65.8663 80.7963L65.2152 79.6365L63.3094 80.7058L62.9596 80.0826L64.8654 79.0133L64.1774 77.7878L62.1613 78.919L61.798 78.272L64.5837 76.709Z"
                      fill="#101010" />
                    <path
                      d="M59.7573 79.2496L61.5203 83.858L59.6803 84.5615C58.6964 84.9377 57.887 84.6213 57.5461 83.7302L57.5437 83.7239C57.3066 83.1043 57.447 82.4759 57.9092 82.0759L56.1955 80.6115L57.1347 80.2524L58.7017 81.6301L59.5929 81.2893L58.9331 79.5648L59.7573 79.2496ZM59.8421 81.9408L58.9126 82.2962C58.3919 82.4953 58.1952 82.9 58.3845 83.395L58.387 83.4014C58.5715 83.8836 59.0097 84.0528 59.5272 83.855L60.4408 83.5056L59.8421 81.9408Z"
                      fill="#101010" />
                    <path
                      d="M48.6592 82.2368L49.0195 87.1575L45.8814 87.3871L45.8272 86.6471L48.0853 86.4819L47.9787 85.0258L45.9151 85.1768L45.8626 84.4607L47.9262 84.3097L47.7792 82.3012L48.6592 82.2368Z"
                      fill="#101010" />
                    <path
                      d="M41.4587 82.2752C42.8981 82.3805 43.7235 83.4248 43.609 84.9901L43.6085 84.9969C43.4941 86.5587 42.5186 87.4712 41.086 87.3664C39.65 87.2613 38.8245 86.217 38.9389 84.6552L38.9394 84.6484C39.054 83.0832 40.0193 82.1699 41.4587 82.2752ZM41.4028 83.039C40.5125 82.9739 39.9124 83.6294 39.833 84.7138L39.8325 84.7206C39.7527 85.8118 40.2619 86.5382 41.1419 86.6025C42.0185 86.6667 42.6312 86.0259 42.7114 84.9312L42.7119 84.9244C42.792 83.8298 42.2828 83.1034 41.4028 83.039Z"
                      fill="#101010" />
                    <path
                      d="M37.5777 81.9306L36.5047 86.7464L34.5818 86.3182C33.5537 86.0892 33.0532 85.3788 33.2607 84.4476L33.2622 84.441C33.4064 83.7935 33.8704 83.3469 34.4766 83.2682L33.8556 81.1016L34.837 81.3202L35.3835 83.3335L36.3149 83.541L36.7165 81.7388L37.5777 81.9306ZM36.1632 84.2218L35.1918 84.0054C34.6476 83.8843 34.2602 84.1133 34.145 84.6305L34.1435 84.6372C34.0312 85.1412 34.3033 85.524 34.8441 85.6445L35.7988 85.8571L36.1632 84.2218Z"
                      fill="#101010" />
                    <path
                      d="M26.6738 78.2999L24.2584 82.6025L21.4728 81.0396L21.836 80.3926L23.8521 81.5238L24.5033 80.3639L22.5975 79.2946L22.9473 78.6714L24.8531 79.7407L25.5411 78.5152L23.5249 77.384L23.8881 76.737L26.6738 78.2999Z"
                      fill="#101010" />
                    <path
                      d="M22.378 75.8041L19.5861 76.7894L19.5799 76.7975L19.351 79.7009L18.5253 79.0635L18.7535 76.8939L18.7237 76.8709L16.6846 77.6425L15.9023 77.0386L18.712 76.0973L18.7183 76.0892L18.9402 73.1501L19.7441 73.7708L19.5573 75.9204L19.5816 75.9392L21.6174 75.2168L22.378 75.8041Z"
                      fill="#101010" />
                    <path
                      d="M16.3147 70.4748C17.2998 71.4963 17.2176 72.82 16.0826 73.914L16.0801 73.9164C14.9451 75.0104 13.6215 75.037 12.6388 74.018C11.8507 73.2007 11.7811 72.1089 12.4148 71.3176L12.4371 71.2915L13.0376 71.9142L13.0027 71.9716C12.6962 72.4666 12.744 73.0236 13.1903 73.4864C13.7885 74.1067 14.6656 74.0259 15.4534 73.2665L15.4559 73.2642C16.2462 72.5024 16.359 71.6242 15.7632 71.0064C15.3264 70.5535 14.7765 70.4709 14.2266 70.7776L14.1949 70.794L13.5943 70.1712L13.6237 70.1524C14.4403 69.541 15.5266 69.6576 16.3147 70.4748Z"
                      fill="#101010" />
                    <path
                      d="M13.6584 67.5903L9.6447 70.4611L9.13128 69.7437L12.5414 67.3046L11.238 65.4832L11.8416 65.0514L13.6584 67.5903Z"
                      fill="#101010" />
                    <path
                      d="M9.84602 61.4098C10.4184 62.5227 10.121 63.5521 9.15069 64.0509L6.30983 65.5111L5.90637 64.7265L8.68336 63.2992C9.29168 62.9865 9.49935 62.4107 9.1647 61.76C8.83004 61.1092 8.24237 60.9461 7.63405 61.2588L4.85706 62.6861L4.45516 61.9047L7.29603 60.4444C8.2663 59.9457 9.2721 60.2938 9.84602 61.4098Z"
                      fill="#101010" />
                    <path
                      d="M7.66817 56.3058C8.03369 57.3769 7.76946 58.2546 6.94988 58.5955L6.90243 58.6153L6.62968 57.816L6.66631 57.7963C7.04671 57.5979 7.1557 57.102 6.95583 56.5162C6.75485 55.9273 6.36678 55.6478 5.9654 55.7847L5.96217 55.7858C5.61258 55.905 5.50766 56.2117 5.58682 56.8567L5.6544 57.3936C5.77704 58.3778 5.50437 58.9766 4.80844 59.2139L4.8052 59.215C3.98193 59.4922 3.17893 58.9605 2.8355 57.954C2.48766 56.9347 2.7975 56.0848 3.58045 55.7636L3.63437 55.7416L3.90712 56.5409L3.87049 56.5606C3.48141 56.7547 3.36122 57.1967 3.54011 57.7209C3.72997 58.2667 4.10164 58.5193 4.47388 58.3923L4.47711 58.3912C4.80728 58.2786 4.91425 57.9568 4.83864 57.3539L4.76995 56.8138C4.63936 55.7745 4.8896 55.2159 5.6179 54.9675L5.62113 54.9664C6.49186 54.6694 7.27947 55.1667 7.66817 56.3058Z"
                      fill="#101010" />
                    <path d="M6.23388 51.3576L1.38081 52.2538L1.22054 51.3863L6.0736 50.4901L6.23388 51.3576Z"
                      fill="#101010" />
                    <path
                      d="M5.53179 45.8223L0.662381 47.7109L0.627282 46.7508L4.57354 45.3543L4.57291 45.3372L0.535099 44.2291L0.5 43.2689L5.49444 44.8006L5.53179 45.8223Z"
                      fill="#101010" />
                    <path
                      d="M5.53594 41.1198L0.630389 40.5802L0.979759 37.4058L1.71746 37.4869L1.4646 39.7844L2.78702 39.9299L3.02605 37.7582L3.73655 37.8363L3.49753 40.0081L4.89474 40.1618L5.14761 37.8643L5.88531 37.9454L5.53594 41.1198Z"
                      fill="#101010" />
                    <path
                      d="M8.60672 28.719C8.16397 29.7606 7.38134 30.238 6.54336 29.9451L6.49481 29.9282L6.8252 29.151L6.86565 29.1607C7.28137 29.2667 7.69361 28.9701 7.93572 28.4005C8.17916 27.8278 8.07669 27.3607 7.68638 27.1949L7.68324 27.1935C7.34329 27.0491 7.06041 27.2076 6.68885 27.7407L6.3808 28.1857C5.81521 29.0005 5.21246 29.2645 4.53571 28.977L4.53257 28.9757C3.7344 28.6329 3.49129 27.7011 3.90729 26.7225C4.32863 25.7312 5.12669 25.3049 5.92422 25.588L5.97907 25.6076L5.64868 26.3849L5.60823 26.3751C5.18889 26.2601 4.80434 26.5092 4.58765 27.0189C4.36474 27.5521 4.47296 27.9882 4.83494 28.1419L4.83808 28.1433C5.15914 28.2797 5.45367 28.1113 5.79973 27.6119L6.10912 27.1638C6.70556 26.3027 7.2648 26.0536 7.97302 26.3544L7.97616 26.3558C8.82288 26.7155 9.07756 27.6113 8.60672 28.719Z"
                      fill="#101010" />
                    <path
                      d="M10.3047 24.9557L6.09204 22.3855L7.7561 19.6595L8.3896 20.046L7.18521 22.019L8.32083 22.7119L9.45931 20.8469L10.0694 21.2192L8.93097 23.0842L10.1308 23.8162L11.3352 21.8432L11.9687 22.2298L10.3047 24.9557Z"
                      fill="#101010" />
                    <path
                      d="M13.2698 20.3787L9.47643 17.2226L10.7366 15.7088C11.4104 14.8994 12.2712 14.7793 13.0046 15.3895L13.0099 15.3939C13.5199 15.8182 13.7051 16.4349 13.4979 17.0098L15.7091 17.4483L15.0659 18.221L13.0251 17.7863L12.4147 18.5196L13.8343 19.7006L13.2698 20.3787ZM11.8784 18.0734L12.515 17.3086C12.8716 16.8802 12.8451 16.4311 12.4376 16.0921L12.4323 16.0877C12.0354 15.7574 11.5704 15.8243 11.216 16.25L10.5903 17.0017L11.8784 18.0734Z"
                      fill="#101010" />
                    <path
                      d="M17.8672 15.3485L13.3003 12.8147L14.016 12.1736L17.614 14.3128L17.6267 14.3014L15.8959 10.4897L16.6116 9.84854L18.6288 14.6663L17.8672 15.3485Z"
                      fill="#101010" />
                    <path d="M22.3444 11.7755L19.6214 7.66066L20.3573 7.17392L23.0803 11.2888L22.3444 11.7755Z"
                      fill="#101010" />
                    <path
                      d="M27.9078 8.7111C26.6227 9.31339 25.3961 8.80821 24.7269 7.381L24.7255 7.3779C24.0562 5.9507 24.4584 4.68977 25.7404 4.08893C26.7685 3.6071 27.8246 3.89383 28.3691 4.74888L28.3867 4.77838L27.6032 5.14556L27.5602 5.094C27.1906 4.64413 26.6478 4.50957 26.0656 4.78241C25.2852 5.14814 25.0785 6.00408 25.543 6.99476L25.5444 6.99786C26.0104 7.99164 26.8053 8.3819 27.5826 8.01762C28.1524 7.75058 28.4082 7.25687 28.2953 6.63759L28.2901 6.60228L29.0735 6.2351L29.0819 6.26896C29.397 7.23904 28.9359 8.22927 27.9078 8.7111Z"
                      fill="#101010" />
                    <path
                      d="M31.4961 7.12933L30.0743 2.40464L33.1332 1.48459L33.347 2.19509L31.1331 2.861L31.5164 4.13467L33.6091 3.50521L33.8151 4.18952L31.7223 4.81898L32.1273 6.16468L34.3412 5.49877L34.555 6.20928L31.4961 7.12933Z"
                      fill="#101010" />
                    <path
                      d="M38.4876 5.53078C37.3676 5.69557 36.5523 5.27639 36.3658 4.40872L36.3549 4.35847L37.1907 4.23551L37.2034 4.27511C37.3295 4.68511 37.7975 4.88236 38.41 4.79225C39.0258 4.70164 39.3712 4.37089 39.3094 3.95142L39.3089 3.94804C39.2552 3.5827 38.9725 3.42384 38.3237 3.38451L37.7833 3.35343C36.793 3.29523 36.2536 2.91841 36.1465 2.19112L36.146 2.18773C36.0229 1.32802 36.6918 0.635166 37.7441 0.480339C38.81 0.323521 39.5896 0.782503 39.7634 1.61057L39.7752 1.66758L38.9395 1.79055L38.9268 1.75095C38.8065 1.33319 38.3936 1.13474 37.8454 1.21539C37.2741 1.30291 36.9581 1.62242 37.0154 2.01143L37.0159 2.01482C37.0667 2.35986 37.3639 2.52349 37.9706 2.55865L38.5143 2.58923C39.5603 2.64961 40.0643 2.99709 40.1763 3.75821L40.1768 3.7616C40.3108 4.67155 39.6786 5.35555 38.4876 5.53078Z"
                      fill="#101010" />
                  </g>
                </svg>
              </v-col>
            </v-row>
            <h4 class="svg-suptext fontRaleway mt-4 mt-sm-8">
              {{ t(`$vuetify.service.green_team.svg_subtitle`) }}
            </h4>
          </v-col>

          <v-col cols="12" sm="3" class="pl-sm-0">
            <v-row justify="end" v-if="$vuetify.display.smAndUp">
              <v-col cols="11" class="">
                <v-img src="../assets/img/GreenTeam/2.jpg"></v-img>
              </v-col>
            </v-row>
            <v-row :justify="$vuetify.display.xs ? 'space-between' : 'center'" class="mt-sm-15">
              <v-col v-if="$vuetify.display.xs" cols="4" class="pa-sm-0" alignSelf="end">
                <v-img src="../assets/img/GreenTeam/3.jpg"></v-img>
              </v-col>
              <v-col cols="6" class="pa-sm-0" :class="$vuetify.display.xs ? 'pb-10' : ''">
                <v-img src="../assets/img/GreenTeam/4.jpg"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: 'GreenTeamPage',
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
  }),
}
</script>

<style scoped>
.svg-suptext {
  color: #000;
  text-align: center;
  font-size: 1.9rem;
  font-weight: 300;
  line-height: 3rem;
  letter-spacing: 3.85px;
  text-transform: uppercase;
  text-wrap: balance;
  white-space: normal;
}

.text-title {
  color: #343330;
  font-weight: 300;
  font-size: 5rem;
  letter-spacing: 8.8px;
  line-height: 6rem;
  text-align: center;
  text-transform: uppercase;
}

.text-subtitle {
  color: #343330;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
}

@media (min-width: 600px) and (max-width: 960px) {
  .text-title {
    font-size: 3.8rem;
    font-weight: 300;
    line-height: 5rem;
  }

  .svg-suptext {
    font-size: 1.3rem;
    letter-spacing: 2.5px;
    font-weight: 400;
  }
}

@media (max-width: 600px) {
  .text-title {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 4px;
    line-height: 3rem;
  }

  .svg-suptext {
    font-size: 1.3rem;
    letter-spacing: 2.5px;
    font-weight: 400;
  }

  .text-subtitle {
    font-size: 1rem;
  }
}

.svg-animation {
  overflow: visible;
  transform-origin: 50% 50%;
  animation-name: svg-anim-navigation;
  animation-duration: 18s;
  animation-direction: normal;
  animation-timing-function: linear;
  transform: rotate(0deg);
  animation-iteration-count: infinite;
}

@keyframes svg-anim-navigation {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.container-main {
  padding-top: 10vh;
  padding-bottom: 5vh;
}
</style>
