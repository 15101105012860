<template>
  <v-container fluid class="container-main">
    <v-row justify="center">
      <v-col cols="12" sm="11" md="11">
        <v-row justify="space-between">
          <v-col cols="10" sm="10" align-self="center">
            <h2 class="text-title">
              {{ t(`$vuetify.home.products.title`) }}
            </h2>
          </v-col>
          <v-col cols="2" align-self="center">
            <svg width="100%" class="px-md-10" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g class="svg-animation">
                <path
                  d="M62.9163 7.36047C60.8402 7.36047 59.5483 5.9505 59.5483 3.65312V3.64819C59.5483 1.39026 60.8402 0 62.8917 0C64.5781 0 65.6729 0.976138 65.9441 2.28752L65.9638 2.36639H64.6718L64.657 2.31217C64.3858 1.54802 63.7694 1.10432 62.8966 1.10432C61.6145 1.10432 60.8452 2.04102 60.8452 3.63833V3.64326C60.8452 5.27509 61.649 6.25616 62.9311 6.25616C64.0061 6.25616 64.7458 5.59554 64.7705 4.59968L64.7754 4.49122H63.01V3.50029H66.0181V4.26937C66.0181 6.20193 64.8642 7.36047 62.9163 7.36047Z"
                  fill="#101010" />
                <path
                  d="M68.5429 7.44774L69.5789 0.409573L72.3891 0.823031C73.8917 1.04412 74.6861 2.01311 74.4858 3.37392L74.4844 3.38367C74.3451 4.3299 73.725 5.02101 72.8615 5.19794L73.9826 8.2481L72.5483 8.03706L71.5504 5.19938L70.1893 4.99911L69.8016 7.63293L68.5429 7.44774ZM70.3358 4.00411L71.7555 4.21299C72.5507 4.32999 73.0837 3.95993 73.195 3.20393L73.1964 3.19417C73.3048 2.45768 72.8732 1.93573 72.0829 1.81945L70.6876 1.61416L70.3358 4.00411Z"
                  fill="#101010" />
                <path
                  d="M76.8689 8.9292L78.9189 2.11685L83.3294 3.44344L83.0211 4.46789L79.829 3.50775L79.2763 5.3442L82.2938 6.25179L81.9968 7.23847L78.9794 6.33088L78.3955 8.2712L81.5877 9.23134L81.2794 10.2558L76.8689 8.9292Z"
                  fill="#101010" />
                <path
                  d="M84.6055 11.5212L87.6259 5.07987L91.7962 7.03433L91.342 8.00299L88.3236 6.58841L87.5094 8.32484L90.3625 9.662L89.9251 10.5949L87.0719 9.25779L86.2117 11.0924L89.23 12.507L88.7758 13.4757L84.6055 11.5212Z"
                  fill="#101010" />
                <path
                  d="M91.1391 14.7202L95.0654 8.78717L96.0195 9.41826L96.1599 15.4874L96.1846 15.5037L98.9354 11.3469L99.9883 12.0433L96.062 17.9763L95.1161 17.3507L94.9784 11.2596L94.9578 11.246L92.1961 15.4193L91.1391 14.7202Z"
                  fill="#101010" />
                <path
                  d="M98.1082 19.532L101.435 12.9606L102.42 13.8423L99.758 18.6665L99.7763 18.683L104.421 15.6352L105.281 16.4051L102.759 21.3544L102.777 21.3708L107.282 18.1981L108.267 19.0798L102.097 23.1049L101.182 22.2857L103.519 17.5281L103.497 17.5084L99.0227 20.3512L98.1082 19.532Z"
                  fill="#101010" />
                <path
                  d="M105.791 27.3565C104.459 25.7574 104.718 23.8557 106.457 22.4082L106.465 22.4019C108.201 20.9575 110.125 21.0567 111.45 22.6482C112.778 24.2436 112.519 26.1453 110.783 27.5896L110.776 27.5959C109.036 29.0434 107.122 28.9557 105.791 27.3565ZM106.64 26.6501C107.463 27.6392 108.744 27.6059 109.949 26.6031L109.957 26.5968C111.17 25.5876 111.414 24.3323 110.601 23.3546C109.79 22.3807 108.511 22.3862 107.295 23.3985L107.287 23.4048C106.07 24.4171 105.826 25.6724 106.64 26.6501Z"
                  fill="#101010" />
                <path
                  d="M109.316 31.7285L115.39 28.0225L116.87 30.4465C117.661 31.7426 117.424 32.9729 116.25 33.6894L116.242 33.6946C115.425 34.1928 114.497 34.1584 113.809 33.608L112.181 36.4206L111.425 35.1834L112.969 32.6015L112.252 31.4274L109.979 32.8142L109.316 31.7285ZM113.111 30.9034L113.858 32.128C114.277 32.814 114.903 32.9868 115.555 32.5887L115.563 32.5836C116.199 32.1958 116.324 31.5303 115.908 30.8486L115.173 29.645L113.111 30.9034Z"
                  fill="#101010" />
                <path
                  d="M113.503 39.074L120.052 36.2917L120.549 37.4623L114.985 39.8262L116.248 42.7982L115.264 43.2166L113.503 39.074Z"
                  fill="#101010" />
                <path
                  d="M116.309 46.1028L123.193 44.3035L123.851 46.8171C124.387 48.8681 123.466 50.4133 121.29 50.9819L121.281 50.9844C119.101 51.5543 117.505 50.677 116.966 48.6165L116.309 46.1028ZM117.671 47.0616L117.973 48.2158C118.334 49.5991 119.378 50.1415 120.948 49.7312L120.958 49.7287C122.494 49.3272 123.135 48.3291 122.777 46.9602L122.475 45.8059L117.671 47.0616Z"
                  fill="#101010" />
                <path d="M121.236 63.4269L122.33 63.4669L122.213 66.6446L121.119 66.6046L121.236 63.4269Z"
                  fill="#101010" />
                <path
                  d="M116.73 78.5924L123.464 80.8894L122.579 83.4837C122.114 84.8461 120.897 85.4674 119.548 85.0074L119.538 85.0042C118.194 84.5458 117.606 83.3084 118.071 81.946L118.545 80.5555L116.319 79.7962L116.73 78.5924ZM121.696 82.8492L122.069 81.7573L119.516 80.8866L119.143 81.9785C118.849 82.8417 119.143 83.5048 119.951 83.7801L119.96 83.7833C120.768 84.0587 121.402 83.7124 121.696 82.8492Z"
                  fill="#101010" />
                <path
                  d="M114.257 85.5419L119.422 91.057L118.768 92.3285L111.277 91.3381L111.888 90.1499L113.799 90.4558L115.009 88.1014L113.649 86.7256L114.257 85.5419ZM117.975 91.1003L115.736 88.835L114.818 90.6194L117.962 91.1266L117.975 91.1003Z"
                  fill="#101010" />
                <path
                  d="M109.978 93.4024L115.765 97.5417L114.113 99.8512C113.229 101.086 112.004 101.349 110.885 100.549L110.877 100.543C110.099 99.9868 109.767 99.1194 110.005 98.2709L106.779 97.873L107.622 96.6942L110.603 97.1045L111.404 95.9858L109.238 94.4368L109.978 93.4024ZM112.222 96.571L111.387 97.7378C110.919 98.3913 111.005 99.0344 111.626 99.479L111.634 99.4848C112.24 99.9179 112.901 99.7729 113.366 99.1233L114.187 97.9766L112.222 96.571Z"
                  fill="#101010" />
                <path
                  d="M103.851 101.167L108.203 105.362L109.682 103.828L110.452 104.571L106.619 108.546L105.849 107.803L107.324 106.274L102.972 102.079L103.851 101.167Z"
                  fill="#101010" />
                <path
                  d="M99.7152 105.277L104.064 110.908L103.159 111.607L97.3678 109.78L97.3444 109.798L100.391 113.743L99.3922 114.514L95.0432 108.883L95.9409 108.19L101.752 110.027L101.771 110.012L98.7121 106.051L99.7152 105.277Z"
                  fill="#101010" />
                <path
                  d="M92.3997 110.604L95.8823 116.807L91.8658 119.061L91.3421 118.128L94.2491 116.497L93.3103 114.825L90.5624 116.366L90.058 115.468L92.8059 113.926L91.8139 112.159L88.907 113.79L88.3832 112.857L92.3997 110.604Z"
                  fill="#101010" />
                <path
                  d="M85.4408 114.267L87.9827 120.911L85.3298 121.926C83.9112 122.468 82.744 122.012 82.2525 120.727L82.249 120.718C81.9073 119.825 82.1097 118.919 82.776 118.342L80.3052 116.231L81.6593 115.713L83.9187 117.699L85.2037 117.208L84.2524 114.721L85.4408 114.267ZM85.5631 118.147L84.2228 118.66C83.472 118.947 83.1884 119.53 83.4614 120.244L83.465 120.253C83.7309 120.948 84.3628 121.192 85.109 120.907L86.4263 120.403L85.5631 118.147Z"
                  fill="#101010" />
                <path
                  d="M69.4389 118.574L69.9583 125.669L65.4336 126L65.3555 124.933L68.6113 124.695L68.4576 122.595L65.4822 122.813L65.4066 121.781L68.382 121.563L68.17 118.667L69.4389 118.574Z"
                  fill="#101010" />
                <path
                  d="M59.0567 118.629C61.1322 118.781 62.3223 120.287 62.1571 122.544L62.1564 122.554C61.9915 124.806 60.5849 126.121 58.5193 125.97C56.4488 125.819 55.2586 124.313 55.4235 122.061L55.4242 122.051C55.5894 119.794 56.9813 118.477 59.0567 118.629ZM58.9761 119.731C57.6925 119.637 56.8272 120.582 56.7128 122.145L56.712 122.155C56.5969 123.729 57.3311 124.776 58.5999 124.869C59.8639 124.961 60.7474 124.037 60.8629 122.459L60.8636 122.449C60.9792 120.871 60.245 119.824 58.9761 119.731Z"
                  fill="#101010" />
                <path
                  d="M53.4609 118.132L51.9137 125.076L49.1412 124.459C47.6588 124.129 46.9372 123.104 47.2364 121.762L47.2385 121.752C47.4465 120.819 48.1154 120.175 48.9895 120.061L48.0941 116.937L49.5092 117.252L50.2972 120.155L51.6401 120.454L52.2191 117.856L53.4609 118.132ZM51.4213 121.436L50.0207 121.124C49.2361 120.949 48.6775 121.28 48.5113 122.025L48.5092 122.035C48.3473 122.762 48.7396 123.314 49.5194 123.487L50.896 123.794L51.4213 121.436Z"
                  fill="#101010" />
                <path
                  d="M37.7389 112.898L34.2563 119.101L30.2398 116.848L30.7635 115.915L33.6705 117.546L34.6094 115.874L31.8615 114.332L32.3659 113.433L35.1138 114.975L36.1057 113.208L33.1987 111.577L33.7224 110.644L37.7389 112.898Z"
                  fill="#101010" />
                <path
                  d="M31.545 109.299L27.5195 110.72L27.5105 110.731L27.1804 114.918L25.99 113.999L26.319 110.87L26.2761 110.837L23.3359 111.95L22.2079 111.079L26.2592 109.722L26.2682 109.71L26.5881 105.472L27.7474 106.367L27.4779 109.467L27.5131 109.494L30.4483 108.452L31.545 109.299Z"
                  fill="#101010" />
                <path
                  d="M22.8026 101.615C24.223 103.088 24.1045 104.996 22.4679 106.574L22.4644 106.577C20.8278 108.155 18.9194 108.193 17.5024 106.724C16.3661 105.545 16.2658 103.971 17.1795 102.83L17.2116 102.792L18.0775 103.69L18.0272 103.773C17.5852 104.487 17.6542 105.29 18.2976 105.957C19.1602 106.851 20.4248 106.735 21.5608 105.64L21.5643 105.637C22.7039 104.538 22.8665 103.272 22.0074 102.381C21.3777 101.728 20.5847 101.609 19.7919 102.051L19.7461 102.075L18.8802 101.177L18.9226 101.15C20.1 100.268 21.6663 100.437 22.8026 101.615Z"
                  fill="#101010" />
                <path
                  d="M18.9726 97.4558L13.1854 101.595L12.4451 100.561L17.362 97.0438L15.4826 94.4176L16.3529 93.7951L18.9726 97.4558Z"
                  fill="#101010" />
                <path
                  d="M13.4756 88.5443C14.3009 90.149 13.8721 91.6333 12.4731 92.3524L8.37697 94.4578L7.79523 93.3266L11.7993 91.2686C12.6764 90.8177 12.9758 89.9875 12.4933 89.0492C12.0108 88.111 11.1634 87.8757 10.2863 88.3266L6.28227 90.3847L5.7028 89.2579L9.79892 87.1525C11.1979 86.4334 12.6481 86.9352 13.4756 88.5443Z"
                  fill="#101010" />
                <path
                  d="M10.3355 81.1851C10.8625 82.7295 10.4816 83.995 9.29983 84.4866L9.23142 84.5151L8.83814 83.3627L8.89096 83.3342C9.43944 83.0482 9.5966 82.333 9.30841 81.4885C9.01863 80.6393 8.45908 80.2363 7.88035 80.4337L7.87568 80.4353C7.37163 80.6072 7.22035 81.0495 7.33448 81.9794L7.43193 82.7536C7.60875 84.1726 7.2156 85.036 6.21216 85.3782L6.2075 85.3798C5.02045 85.7795 3.86264 85.0127 3.36747 83.5616C2.86592 82.0919 3.31267 80.8664 4.44157 80.4033L4.51932 80.3716L4.9126 81.524L4.85978 81.5525C4.29878 81.8324 4.12548 82.4697 4.38342 83.2255C4.65717 84.0125 5.19306 84.3767 5.72978 84.1936L5.73444 84.192C6.21049 84.0297 6.36474 83.5655 6.25571 82.6964L6.15667 81.9175C5.96837 80.4191 6.32919 79.6136 7.37929 79.2555L7.38396 79.2539C8.63942 78.8257 9.77505 79.5427 10.3355 81.1851Z"
                  fill="#101010" />
                <path d="M8.26745 74.0505L1.27001 75.3426L1.03892 74.0919L8.03636 72.7997L8.26745 74.0505Z"
                  fill="#101010" />
                <path
                  d="M7.25514 66.0693L0.234131 68.7925L0.183523 67.4081L5.87347 65.3946L5.87257 65.37L0.0506083 63.7722L0 62.3878L7.20129 64.5962L7.25514 66.0693Z"
                  fill="#101010" />
                <path
                  d="M7.26112 59.289L0.188003 58.5109L0.691746 53.9339L1.75541 54.0509L1.39082 57.3636L3.29757 57.5734L3.64221 54.442L4.66666 54.5547L4.32202 57.6861L6.33661 57.9077L6.7012 54.595L7.76486 54.712L7.26112 59.289Z"
                  fill="#101010" />
                <path
                  d="M11.6888 41.4088C11.0504 42.9106 9.92194 43.599 8.71369 43.1767L8.64368 43.1523L9.12006 42.0316L9.17837 42.0457C9.77779 42.1986 10.3722 41.7708 10.7213 40.9495C11.0723 40.1237 10.9245 39.4503 10.3618 39.2112L10.3572 39.2093C9.86707 39.0011 9.45919 39.2295 8.92346 39.9983L8.47929 40.6398C7.66379 41.8147 6.7947 42.1954 5.81893 41.7808L5.8144 41.7789C4.66355 41.2846 4.31302 39.9412 4.91283 38.5301C5.52035 37.1008 6.67104 36.4862 7.82097 36.8944L7.90005 36.9226L7.42368 38.0433L7.36536 38.0293C6.76072 37.8635 6.20626 38.2225 5.89382 38.9575C5.57242 39.7263 5.72845 40.355 6.25037 40.5768L6.25491 40.5787C6.71784 40.7754 7.14251 40.5326 7.64147 39.8125L8.08757 39.1664C8.94755 37.9248 9.75389 37.5656 10.775 37.9994L10.7796 38.0014C12.0004 38.52 12.3676 39.8116 11.6888 41.4088Z"
                  fill="#101010" />
                <path
                  d="M14.1369 35.9827L8.06294 32.2767L10.4623 28.3463L11.3757 28.9036L9.63913 31.7483L11.2765 32.7474L12.9181 30.0583L13.7978 30.5951L12.1563 33.2841L13.8863 34.3397L15.6229 31.4949L16.5363 32.0522L14.1369 35.9827Z"
                  fill="#101010" />
                <path
                  d="M18.4123 29.3833L12.9428 24.8326L14.7597 22.6499C15.7312 21.4828 16.9724 21.3096 18.0299 22.1895L18.0375 22.1958C18.7729 22.8076 19.0399 23.6968 18.7412 24.5258L21.9294 25.158L21.0021 26.2721L18.0594 25.6454L17.1793 26.7027L19.2261 28.4056L18.4123 29.3833ZM16.4061 26.0593L17.324 24.9566C17.8382 24.3389 17.7999 23.6913 17.2123 23.2025L17.2048 23.1962C16.6324 22.72 15.962 22.8164 15.4509 23.4303L14.5488 24.5141L16.4061 26.0593Z"
                  fill="#101010" />
                <path
                  d="M25.0411 22.1304L18.4562 18.4771L19.4883 17.5526L24.676 20.6371L24.6944 20.6206L22.1987 15.1247L23.2307 14.2002L26.1392 21.1467L25.0411 22.1304Z"
                  fill="#101010" />
                <path d="M31.4966 16.9786L27.5704 11.0456L28.6314 10.3438L32.5577 16.2768L31.4966 16.9786Z"
                  fill="#101010" />
                <path
                  d="M39.5182 12.5602C37.6652 13.4286 35.8968 12.7002 34.9318 10.6424L34.9297 10.6379C33.9648 8.58007 34.5447 6.762 36.3932 5.89567C37.8756 5.20094 39.3983 5.61436 40.1833 6.84722L40.2087 6.88976L39.0791 7.41919L39.017 7.34483C38.4841 6.69619 37.7015 6.50217 36.862 6.89557C35.7369 7.4229 35.4387 8.65704 36.1085 10.0855L36.1106 10.0899C36.7825 11.5228 37.9286 12.0855 39.0493 11.5603C39.8709 11.1753 40.2397 10.4634 40.0769 9.57048L40.0694 9.51956L41.1991 8.99014L41.2111 9.03897C41.6654 10.4377 41.0006 11.8655 39.5182 12.5602Z"
                  fill="#101010" />
                <path
                  d="M44.6921 10.2795L42.642 3.46716L47.0525 2.14057L47.3608 3.16502L44.1686 4.12516L44.7213 5.96161L47.7388 5.05402L48.0357 6.0407L45.0182 6.94829L45.6021 8.88861L48.7943 7.92846L49.1026 8.95291L44.6921 10.2795Z"
                  fill="#101010" />
                <path
                  d="M54.7728 7.97462C53.1579 8.21221 51.9824 7.60781 51.7135 6.35676L51.6978 6.28431L52.9029 6.10701L52.9212 6.16411C53.103 6.75527 53.7778 7.03967 54.6609 6.90975C55.5488 6.77911 56.0468 6.30221 55.9578 5.6974L55.9571 5.69252C55.8795 5.16576 55.472 4.9367 54.5365 4.87999L53.7574 4.83518C52.3295 4.75127 51.5517 4.20793 51.3973 3.15928L51.3966 3.1544C51.2191 1.91481 52.1835 0.91582 53.7008 0.692582C55.2376 0.466472 56.3618 1.12826 56.6123 2.32222L56.6294 2.40442L55.4244 2.58172L55.406 2.52463C55.2326 1.92227 54.6372 1.63614 53.8469 1.75242C53.0231 1.87861 52.5675 2.3393 52.6501 2.90021L52.6508 2.90509C52.7241 3.40259 53.1526 3.63853 54.0273 3.68921L54.8114 3.73332C56.3195 3.82037 57.0462 4.32139 57.2077 5.41882L57.2085 5.42369C57.4016 6.73573 56.4901 7.72195 54.7728 7.97462Z"
                  fill="#101010" />
              </g>
              <g>
                <path
                  d="M72.2667 61.6H71.2767L68.7302 55.8708C68.6149 55.6116 68.427 55.3914 68.1891 55.2368C67.9513 55.0823 67.6737 55 67.39 55H56.61C56.3263 55 56.0487 55.0823 55.8109 55.2368C55.573 55.3914 55.3851 55.6116 55.2698 55.8708L52.7233 61.6H51.7333C51.5388 61.6 51.3523 61.6773 51.2148 61.8148C51.0773 61.9523 51 62.1388 51 62.3333C51 62.5278 51.0773 62.7144 51.2148 62.8519C51.3523 62.9894 51.5388 63.0667 51.7333 63.0667H52.4667V70.4C52.4667 70.789 52.6212 71.162 52.8962 71.4371C53.1713 71.7121 53.5443 71.8667 53.9333 71.8667H56.1333C56.5223 71.8667 56.8954 71.7121 57.1704 71.4371C57.4455 71.162 57.6 70.789 57.6 70.4V68.9333H66.4V70.4C66.4 70.789 66.5545 71.162 66.8296 71.4371C67.1046 71.7121 67.4777 71.8667 67.8667 71.8667H70.0667C70.4557 71.8667 70.8287 71.7121 71.1038 71.4371C71.3788 71.162 71.5333 70.789 71.5333 70.4V63.0667H72.2667C72.4612 63.0667 72.6477 62.9894 72.7852 62.8519C72.9227 62.7144 73 62.5278 73 62.3333C73 62.1388 72.9227 61.9523 72.7852 61.8148C72.6477 61.6773 72.4612 61.6 72.2667 61.6ZM57.6 66H56.1333C55.9388 66 55.7523 65.9227 55.6148 65.7852C55.4773 65.6477 55.4 65.4612 55.4 65.2667C55.4 65.0722 55.4773 64.8856 55.6148 64.7481C55.7523 64.6106 55.9388 64.5333 56.1333 64.5333H57.6C57.7945 64.5333 57.981 64.6106 58.1185 64.7481C58.2561 64.8856 58.3333 65.0722 58.3333 65.2667C58.3333 65.4612 58.2561 65.6477 58.1185 65.7852C57.981 65.9227 57.7945 66 57.6 66ZM66.4 66C66.2055 66 66.019 65.9227 65.8815 65.7852C65.7439 65.6477 65.6667 65.4612 65.6667 65.2667C65.6667 65.0722 65.7439 64.8856 65.8815 64.7481C66.019 64.6106 66.2055 64.5333 66.4 64.5333H67.8667C68.0612 64.5333 68.2477 64.6106 68.3852 64.7481C68.5227 64.8856 68.6 65.0722 68.6 65.2667C68.6 65.4612 68.5227 65.6477 68.3852 65.7852C68.2477 65.9227 68.0612 66 67.8667 66H66.4Z"
                  fill="#343330" />
              </g>
            </svg>
          </v-col>
        </v-row>
        <v-row justify="space-between" class="mt-8">
          <v-col cols="12" sm="7" align-self="center">
            <v-row justify="start">
              <v-col cols="1" sm="2" md="1" align-self="center">
                <svg width="100%" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M33.8333 10.5H32.2583L28.2071 1.38542C28.0237 0.973039 27.7248 0.622678 27.3463 0.376782C26.9679 0.130885 26.5263 5.40195e-06 26.075 0H8.925C8.4737 5.40195e-06 8.03209 0.130885 7.65367 0.376782C7.27525 0.622678 6.97626 0.973039 6.79292 1.38542L2.74167 10.5H1.16667C0.857247 10.5 0.560501 10.6229 0.341709 10.8417C0.122916 11.0605 0 11.3572 0 11.6667C0 11.9761 0.122916 12.2728 0.341709 12.4916C0.560501 12.7104 0.857247 12.8333 1.16667 12.8333H2.33333V24.5C2.33333 25.1188 2.57917 25.7123 3.01675 26.1499C3.45434 26.5875 4.04783 26.8333 4.66667 26.8333H8.16667C8.78551 26.8333 9.379 26.5875 9.81658 26.1499C10.2542 25.7123 10.5 25.1188 10.5 24.5V22.1667H24.5V24.5C24.5 25.1188 24.7458 25.7123 25.1834 26.1499C25.621 26.5875 26.2145 26.8333 26.8333 26.8333H30.3333C30.9522 26.8333 31.5457 26.5875 31.9832 26.1499C32.4208 25.7123 32.6667 25.1188 32.6667 24.5V12.8333H33.8333C34.1427 12.8333 34.4395 12.7104 34.6583 12.4916C34.8771 12.2728 35 11.9761 35 11.6667C35 11.3572 34.8771 11.0605 34.6583 10.8417C34.4395 10.6229 34.1427 10.5 33.8333 10.5Z"
                    fill="#101010" />
                </svg>
              </v-col>
              <v-col cols="8" sm="10" md="11" align-self="center" class="fontSFProR title-text">
                {{ t(`$vuetify.home.products.subtitle`) }}
              </v-col>
            </v-row>
          </v-col>
          <v-col offset="1" offset-sm="0" cols="10" md="4" lg="3" align-self="center"
            class="description-text fontSFProR">
            {{ t(`$vuetify.home.products.text_right`) }}
          </v-col>
        </v-row>
        <v-row justify="space-between" class="mt-10">
          <v-col cols="12" sm="12" md="4" lg="3" align-self="start">
            <v-btn @click="goToProducts()" variant="text" size="large" class="btn-see-all px-1">
              {{ t(`$vuetify.home.products.btn_see_all_cars`) }}
              <template v-slot:append>
                <v-icon>
                  <svg width="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M18 0.999969V13.9996C18 14.2648 17.8946 14.5191 17.7071 14.7067C17.5196 14.8942 17.2652 14.9995 17 14.9995C16.7348 14.9995 16.4805 14.8942 16.2929 14.7067C16.1054 14.5191 16.0001 14.2648 16.0001 13.9996V3.41364L1.708 17.707C1.52037 17.8946 1.26588 18 1.00052 18C0.735169 18 0.480682 17.8946 0.293047 17.707C0.105412 17.5193 0 17.2648 0 16.9995C0 16.7341 0.105412 16.4796 0.293047 16.292L14.5864 1.99994H4.00043C3.73522 1.99994 3.48088 1.89458 3.29335 1.70705C3.10582 1.51952 3.00046 1.26518 3.00046 0.999969C3.00046 0.734761 3.10582 0.480415 3.29335 0.292884C3.48088 0.105354 3.73522 0 4.00043 0H17C17.2652 0 17.5196 0.105354 17.7071 0.292884C17.8946 0.480415 18 0.734761 18 0.999969Z"
                      fill="#4C4C4C" />
                  </svg>
                </v-icon>
              </template>
            </v-btn>
            <v-list v-model:selected="categorie_selected" class="list-categories">
              <v-list-item v-for="(item, index) in getAllCategories" :key="index" :value="item.ID"
                @click="goToProducts(item)" color="primary" class="list-categories-item py-3">
                <template v-slot:prepend>
                  <div class="mr-6 mb-3 list-categories-item-number">
                    {{ getCarsLengthByCategorie(item.ID) }}
                    <small v-if="$vuetify.display.lgAndUp">
                      {{ t(`$vuetify.home.products.vehicles`) }}
                    </small>
                  </div>
                </template>

                <v-list-item-title class="list-categories-item-title fontSFProR">
                  {{ item.name_en }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="9" align-self="center">
            <v-row>
              <v-col cols="6" sm="4" md="4" v-for="(car, index) in AllCars" :key="index" class="pa-2">
                <cardCar :car="car" />
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col class="pa-2">
                <v-btn @click="goToProducts()" color="#101010" variant="flat" size="large" class="btn-see-all-2 fontSFProR">
                  {{ t(`$vuetify.home.products.btn_see_green_worlds`) }}
                  <template v-slot:append>
                    <v-icon>
                      <svg width="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.5 0.643545V11.7983C15.5 11.969 15.4322 12.1327 15.3115 12.2534C15.1908 12.3741 15.0271 12.4419 14.8565 12.4419C14.6858 12.4419 14.5221 12.3741 14.4014 12.2534C14.2807 12.1327 14.2129 11.969 14.2129 11.7983V2.19663L1.58226 14.8273C1.46027 14.941 1.29891 15.0028 1.13219 14.9999C0.965471 14.997 0.806399 14.9294 0.68849 14.8115C0.570581 14.6936 0.503042 14.5345 0.5001 14.3678C0.497159 14.2011 0.559044 14.0397 0.67272 13.9177L13.3034 1.28709H3.70167C3.53099 1.28709 3.36731 1.21929 3.24662 1.0986C3.12593 0.977912 3.05813 0.814224 3.05813 0.643545C3.05813 0.472866 3.12593 0.309178 3.24662 0.18849C3.36731 0.0678018 3.53099 0 3.70167 0H14.8565C15.0271 0 15.1908 0.0678018 15.3115 0.18849C15.4322 0.309178 15.5 0.472866 15.5 0.643545Z"
                          fill="#EEAC47" />
                      </svg>
                    </v-icon>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { useLocale } from "vuetify";
import cardCar from '../cardCar.vue';

export default {
  name: 'ProductsPage',
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    limit_view: 9,
    categorie_selected: "",
  }),
  components: {
    cardCar
  },
  computed: {
    ...mapGetters([
      "getAllCategories",
      "getAllCars",
    ]),
    AllCars() {
      let cars = this.getAllCars;
      /* console.log(this.categorie_selected)
      if (this.categorie_selected) {
        cars = cars.filter((c) => c.ClassID == this.categorie_selected)
      } */
      cars = cars.filter((c) => c.top == true)

      return cars.slice(0, this.limit_view)
    }
  },
  methods: {
    getCarsLengthByCategorie(categ_ID) {
      return this.getAllCars.filter((c) => c.ClassID == categ_ID).length
    },
    goToProducts(categorie) {
      this.$router.push({
        name: "service",
        hash: "#all_cars",
        params: {
          categorie: categorie ? categorie[`link_${this.$vuetify.locale.current}`] : undefined,
          lang: this.$vuetify.locale.current
        }
      });
    }
  }
}
</script>

<style scoped>
.btn-see-all-2 {
  border-radius: 15px;
  color: #E6E8EB;
  font-size: 1.3rem;
  text-transform: inherit;
  letter-spacing: 0.5px;
}

.list-categories-item-number {
  color: #9A9A9B;
}

.list-categories-item-title {
  color: #4C4C4C;
  font-size: 1.5rem;

}

.list-categories-item {
  border-top: 1px solid #9A9A9B;
}

.list-categories {
  background: transparent;
}

.btn-see-all {
  text-transform: inherit;
  letter-spacing: 0px;
  font-size: 1.5rem;
  text-decoration: underline;
  color: #4C4C4C;
}

.title-text {
  font-size: 1.3rem;
  color: #000000;
}

.description-text {
  font-size: .9rem;
  color: #737475;
}

.text-title {
  color: #343330;
  font-weight: 300;
  font-size: 5.5rem;
  line-height: 7rem;
  text-align: left;
  text-transform: uppercase;
}

@media (min-width: 600px) and (max-width: 960px) {
  .text-title {
    font-size: 3.8rem;
    font-weight: 300;
    line-height: 5rem;
  }

  .btn-see-all-2 {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .text-title {
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 3rem;
  }

  .btn-see-all-2 {
    font-size: 1rem;
  }
}


.svg-animation {
  overflow: visible;
  transform-origin: 50% 50%;
  animation-name: svg-anim-2;
  animation-duration: 18s;
  animation-direction: normal;
  animation-timing-function: linear;
  transform: rotate(0deg);
  animation-iteration-count: infinite;
}

@keyframes svg-anim-2 {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.container-main {
  padding-top: 10vh;
  padding-bottom: 5vh;
}
</style>
