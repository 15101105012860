import { de } from "vuetify/lib/locale";

export default {
  $vuetify: { ...de },
  ...de,
  home: {
    header: {
      btn_get_in_touch: "Kontaktieren",
    },
    first_component: {
      title1: {
        part1: "WILLKOMMEN IN",
        part2: "GREENWORLD GBR",
      },
      title2: "IHREM ZUVERLÄSSIGEN PARTNER",
      title3: "FÜR EXKLUSIVE DIENSTLEISTUNGEN.",
    },
    discover: {
      title: {
        part1: "ENTDECKEN SIE",
        part2: "WAS UNS AUSZEICHNET",
      },
      contact_us_text: "Kontakt für unvergessliche Erlebnisse",
      text_right:
        "Wir beraten unsere Kunden gerne und bieten unseren vielfältigen Kunden einen klaren und persönlichen Service. Lassen Sie uns darüber sprechen:",
    },
    parallax: {
      title: "ERSTKLASSIGE DIENSTLEISTUNGEN",
      text: {
        part1: "Green World AR GbR bietet erstklassigen Limousinenservice und",
        part2: "maßgeschneiderte Privatflüge für anspruchsvolle Kunden.",
      },
    },
    services: {
      title: "HI-END DIENSTLEISTUNGEN",
      services: [
        {
          title: "Professionelle Chauffeurdienste",
          description:
            "Ob Sie ein Transportmittel für eine Geschäftsreise, besondere Anlässe oder einfach nur für einen Abend in der Stadt benötigen, unsere Chauffeure sorgen dafür, dass Sie stilvoll und komfortabel ankommen. Unsere Chauffeure sind diskret, pünktlich und aufmerksam und haben es sich zum Ziel gesetzt, Ihre Erwartungen zu übertreffen.",
        },
        {
          title: "Limousinenservice",
          description:
            "Unsere Limousinen sind perfekt für Hochzeiten, Abschlussbälle, Firmenveranstaltungen und andere besondere Anlässe geeignet und mit den modernsten Ausstattungsmerkmalen ausgestattet, um ein luxuriöses Erlebnis zu gewährleisten. Von dem Moment an, in dem Sie in eine unserer eleganten Limousinen einsteigen, werden Sie mit beispiellosem Komfort und Eleganz verwöhnt.",
        },
        {
          title: "Yachtcharter",
          description:
            "Egal, ob Sie einen romantischen Ausflug, einen Familienurlaub oder eine Firmenklausur planen, unsere Yachten sind mit luxuriösen Annehmlichkeiten ausgestattet und mit einer professionellen Crew besetzt, die sich um Ihren Komfort und Ihr Vergnügen kümmert.",
        },
        {
          title: "Privatflüge",
          description:
            "Für diejenigen, die ein Höchstmaß an Exklusivität und Komfort wünschen, ist unser Privatflugservice die ideale Lösung. Wir bieten Zugang zu einer Flotte von Privatjets und Hubschraubern, sodass Sie schnell und in absoluter Privatsphäre reisen können.",
        },
        {
          title: "VIP-Transfers",
          description:
            "Whether you're arriving at the airport, heading to a business meeting, or attending a special event, our VIP transfer service ensures a seamless journey. Our fleet of high-end vehicles and professional chauffeurs are dedicated to providing a first-class experience from start to finish.",
        },
      ],
      btn_request_info: "Anfrage Informationen",
    },
    languages: {
      title: "Alle Dienste sind europaweit verfügbar.",
      text_right:
        "Wir beraten unsere Kunden gerne und bieten unseren vielfältigen Kunden einen klaren und persönlichen Service. Lassen Sie uns darüber sprechen:",
      we_speak_on: "Sprachen, in denen wir arbeiten:",
    },
    products: {
      title: "Außergewöhnliche Flotte",
      subtitle: "Erlesene Flotte luxuriöser Bodenfahrzeuge",
      text_right:
        "Wir sind stolz darauf, eine exklusive Flotte der luxuriösesten Fahrzeuge anzubieten, die dafür sorgen, dass jede Fahrt mit uns ein Ausdruck von Kultiviertheit und Eleganz ist. Unsere Dienste sind in ganz Europa verfügbar.",
      btn_see_all_cars: "See all cars",
      btn_see_green_worlds: "Unsere gesamte Flotte",
      vehicles: "vehicles",
    },
    approach: {
      title: "VIP-Ansatz",
      subtitle: "Entdecken Sie, was uns von anderen unterscheidet",
      text_right:
        "Wir sind überall dort, wo unsere Kunden sind. Wir von Green World AR GbR bieten unsere Leistungen in gleichbleibender Qualität an, unabhängig vom Standort. Unser Engagement für Spitzenleistung und Kundenzufriedenheit kennt keine Grenzen. Erfahren Sie mehr über unsere weltweiten Leistungen auf unserer Website.",
      contact_us_title:
        "Kontaktieren Sie uns jetzt für unvergessliche Erlebnisse mit Green World AR GbR",
      btn_get_in_touch: "In Kontakt kommen",
      exclusive_services: {
        title: "Exklusive Dienstleistungen",
        description:
          "Die Green World AR GbR bietet erstklassigen Limousinenservice und maßgeschneiderte Privatflüge für anspruchsvolle Kunden.",
      },
      comfort_and_style: {
        title: "Komfort und Stil",
        description:
          "Unsere modernen Fahrzeuge und luxuriösen Fluglösungen garantieren höchsten Komfort und Stil.",
      },
      experienced_team: {
        title: "Erfahrenes Team",
        description:
          "Unser Team besteht aus den beiden Partnern Adrian Kusari und Roman Pogreban sowie weiteren 30 freien Mitarbeitern, die sich um Ihr Wohl kümmern.",
      },
      discretion: {
        title: "Diskretion",
        description:
          "Um Ihren Ansprüchen gerecht zu werden, legen wir Wert auf Diskretion und Vertraulichkeit.",
      },
      commitment_to_excellence: {
        title: "Engagement für Spitzenleistungen",
        description:
          "Die Green World AR GbR bietet erstklassigen Limousinenservice und maßgeschneiderte Privatflüge für anspruchsvolle Kunden.",
      },
    },
    navigation: {
      title: "Navigation",
      navigation_list: {
        service: {
          title: "Service",
          description: "Informationen zu unseren Leistungen und unserer Flotte",
        },
        contact: {
          title: "Kontakt",
          description:
            "Für Fragen und Anfragen zu VIP-Diensten stehen wir Ihnen gerne zur Verfügung",
        },
        home: {
          title: "HEIM",
          description: "Greenworld GBR Homepage",
        },
      },
    },
  },
  service: {
    first_component: {
      title1:
        "WIR BIETEN EINZIGARTIGE DIENSTLEISTUNGEN, DIE DEN STATUS UND KOMFORT UNSERER KUNDEN HERVORSTEHEN",
      title2: "INSPIRIEREN SIE ZUM REISEN UND",
      title3: "HEBEN SIE DIE MASSSTAB FÜR SERVICE AUF EIN NEUE NIVEAU.",
      title4: " ",
    },
    all_services: {
      title: "Alle Leistungen",
      left_block: {
        vip_service: {
          part1: "VIP",
          part2: "Service",
        },
        text: "Ob für Geschäftsreisen, besondere Anlässe oder einfach für einen Abend in der Stadt – unsere Chauffeure sorgen dafür, dass Sie stilvoll und komfortabel ankommen. Diskret, pünktlich und aufmerksam, unsere Chauffeure setzen alles daran, Ihre Erwartungen zu übertreffen.",
      },
      right_block: {
        title: "CHAUFFEUR DIENSTE",
        items: {
          confidentiality: {
            title: "Vertraulichkeit",
            text: "Ihre Privatsphäre hat oberste Priorität",
            btn_text: "Buchen Sie jetzt einen Chauffeur",
          },
          safety: {
            title: "Sicherheit",
            text: "Unsere professionellen Chauffeure haben viele Jahre Erfahrung und Hintergrundprüfungen, was Ihre Sicherheit und pünktliche Ankunft am Zielort gewährleistet.",
            btn_text: "Buchen Sie jetzt einen Chauffeur",
          },
          luxury_vehicles: {
            title: "Luxusfahrzeuge",
            text: "Reisen Sie stilvoll mit erstklassigen Autos wie Mercedes-Benz, Lamborghini und anderen.",
            btn_text: "Buchen Sie jetzt einen Chauffeur",
          },
        },
      },
    },
    limousine_services: {
      left_block: {
        vip_service: {
          part1: "VIP",
          part2: "Service",
        },
        text: "Perfekt für Hochzeiten, Abschlussbälle, Firmenveranstaltungen und andere besondere Anlässe, unsere Limousinen sind mit erstklassigen Funktionen ausgestattet, um ein luxuriöses Erlebnis zu gewährleisten. Ab dem Moment, in dem Sie eine unserer eleganten Limousinen betreten, werden Sie unvergleichlichen Komfort und Raffinesse genießen.",
      },
      right_block: {
        title: "LIMOUSINE DIENSTE",
        items: {
          topcars: {
            title: "Top-Fahrzeuge",
            text: "Sehen Sie sich unten unser vollständiges Sortiment an Executive-Class-Fahrzeugen an.",
            btn_text: "Buchen Sie eine Limousine",
          },
          chauffeurs: {
            title: "Chauffeure",
            text: "Unsere professionellen Chauffeure haben viele Jahre Erfahrung und Hintergrundprüfungen, um Ihre Sicherheit und pünktliche Ankunft am Zielort zu gewährleisten.",
            btn_text: "Buchen Sie eine Limousine",
          },
          pricing: {
            title: "Preisgestaltung",
            text: "Die Kosten werden basierend auf dem Tarif und der Fahrzeugauswahl berechnet, die Sie unten sehen können",
            btn_text: "Buchen Sie eine Limousine",
          },
        },
      },
    },
    yacht_charter: {
      left_block: {
        vip_service: {
          part1: "VIP",
          part2: "Service",
        },
        text: "Ob romantischer Kurzurlaub, Familienurlaub oder Firmenausflug – unsere Yachten sind mit luxuriösen Annehmlichkeiten ausgestattet und werden von professionellen Crews betreut, die sich Ihrem Komfort und Vergnügen widmen.",
      },
      right_block: {
        title: "YACHT CHARTER",
        items: {
          team: {
            title: "Team",
            text: "Im Rahmen unserer Yacht-Charter-Services stellen wir eine professionelle Crew zur Verfügung, darunter ein Kapitän, Navigator, Mechaniker, Steward, Koch, Tauchmeister, Deckhand und Bordkoordinator, die ein unvergleichliches Maß an Service und Komfort während Ihrer Reise gewährleisten.",
            btn_text: "Fordern Sie eine Yacht-Charter an",
          },
          pricing: {
            title: "Preisgestaltung",
            text: "Die Kosten werden basierend auf der Anzahl der Gäste, dem Yachtmodell, der Dauer der Charter, dem Zielort und zusätzlichen Optionen berechnet.",
            btn_text: "Fordern Sie eine Yacht-Charter an",
          },
          modern_yachts: {
            title: "Moderne Yachten",
            text: "Wir bieten gut gewartete, moderne Yachten wie Baglietto.",
            btn_text: "Fordern Sie eine Yacht-Charter an",
          },
        },
      },
    },
    vip_jet: {
      left_block: {
        vip_service: {
          part1: "VIP",
          part2: "Service",
        },
        text: "Für diejenigen, die das höchste Maß an Exklusivität und Bequemlichkeit verlangen, ist unser Private Flights Service die ideale Lösung. Wir bieten Zugang zu einer Flotte von Privatjets und Hubschraubern, sodass Sie schnell und in absoluter Privatsphäre reisen können.",
      },
      right_block: {
        title: "VIP JET CHARTERS",
        items: {
          privacy: {
            title: "Privatsphäre",
            text: "Ein Charterjet bietet die Möglichkeit, in Ihrem eigenen privaten Raum zu arbeiten oder sich zu entspannen.",
            btn_text: "Buchen Sie jetzt einen Privatjetflug",
          },
          convenience: {
            title: "Bequemlichkeit",
            text: "Erleben Sie nahtloses Reisen, das auf Ihren Zeitplan zugeschnitten ist.",
            btn_text: "Buchen Sie jetzt einen Privatjetflug",
          },
          no_queues: {
            title: "Keine Warteschlangen",
            text: "Überspringen Sie die Schlangen mit Zugang zu privaten Terminals.",
            btn_text: "Buchen Sie jetzt einen Privatjetflug",
          },
        },
      },
    },
    executive_transfers: {
      left_block: {
        vip_service: {
          part1: "VIP",
          part2: "Service",
        },
        text: "Ob Ankunft am Flughafen, Fahrt zu einem Geschäftstreffen oder Teilnahme an einer besonderen Veranstaltung – unser VIP-Transfer-Service sorgt für eine reibungslose Reise. Unsere Flotte von hochwertigen Fahrzeugen und professionellen Chauffeuren bietet Ihnen von Anfang bis Ende ein erstklassiges Erlebnis.",
      },
      right_block: {
        title: "Transfers für Führungskräfte",
        items: {
          best_drivers: {
            title: "Beste Fahrer",
            text: "Unsere professionellen Fahrer sorgen für eine reibungslose Fahrt.",
            btn_text: "Buchen Sie einen Executive-Transfer",
          },
          ample_space: {
            title: "Viel Platz",
            text: "Genießen Sie die geräumigen Innenräume unserer Premium-Fahrzeuge wie Mercedes-Benz V-Klasse, S-Klasse, G-Klasse, BMW 520 und andere.",
            btn_text: "Buchen Sie einen Executive-Transfer",
          },
          punctuality: {
            title: "Pünktlichkeit",
            text: "Wir garantieren pünktliche An- und Abfahrten.",
            btn_text: "Buchen Sie einen Executive-Transfer",
          },
        },
      },
    },
    pricing: {
      title: "PREISE",
      gold_pack: {
        title: "GOLDPAKET",
        description:
          "VIP-Transfer in Begleitung von 2 V-Klassen und einem Luxusauto. Sie erhalten 3 Chauffeure.",
        price_type: "pro Monat",
        plus_price_car: "Preis für das jeweilige Auto.",
        btn_request_now: "Jetzt anfordern",
      },
      silver_pack: {
        title: "SILBER PAKET",
        description:
          "VIP-Transfer in Begleitung von Luxusauto. Sie erhalten 1 Chauffeur.",
        price_type: "pro Monat",
        plus_price_car: "Preis für das jeweilige Auto.",
        btn_request_now: "Jetzt anfordern",
      },
      bronze_pack: {
        title: "BRONZE PAKET",
        description:
          "VIP-Transfer in Begleitung von Luxusauto. Sie erhalten 1 Chauffeur.",
        price_type: "pro Monat",
        plus_price_car: "Preis für das jeweilige Auto.",
        btn_request_now: "Jetzt anfordern",
      },
      individual_pack: {
        title: "INDIVIDUELL",
        description: "Wird je nach Anfrage berechnet.",
        btn_request_now: "Jetzt anfordern",
      },
    },
    private_jets: {
      title: "Yachten und Privatjets auf individuelle Anfrage",
      text_right:
        "Greenworld GBR bietet eine Flotte luxuriöser Yachten und Privatjets für direkte Sonderanfragen und gewährleistet so beispiellose Exklusivität und Komfort für unsere anspruchsvollen Kunden.",
    },
    green_team: {
      title: "GREENWORLD Mannschaft",
      description:
        "Unser Team wird von den Partnern Adrian Kusari und Roman Pogreban geleitet, die sich der Bereitstellung des bestmöglichen Service verschrieben haben. Gemeinsam mit unseren 30 Mitarbeitern sorgen wir für Ihr Wohlbefinden und Ihre Zufriedenheit. Wir haben mehr als 375 zufriedene Kunden in ganz Europa und sind stets bestrebt, Service von höchster Qualität zu bieten.",
      svg_subtitle: "UNÜBERTROFFENER SERVICE IN GANZ EUROPA",
    },
  },
  cards: {
    btn_book_it_now: "Jetzt reservieren",
  },
  contact: {
    contact_us: {
      title: "KONTAKTIEREN SIE UNS",
      email: "EMAIL",
      phone: "ANRUFEN",
      location: "STANDORT",
    },
  },
  footer: {
    title: "Green World AR GbR , Deutschland.",
    description: "Ihr zuverlässiger Partner für exklusive Dienstleistungen.",
    title_col_2: "Green World AR GbR , Deutschland.",
    btn_terms_and_conditions: "Allgemeine Geschäftsbedingungen",
    btn_privacy: "Datenschutz",
    btn_cookies: "Cookie",
    btn_home: "Heim",
    btn_service: "Service",
    btn_contact: "Kontakt",
  },
  modals: {
    contact_us: {
      title: "Persönliche Reservierung",
      subtitle: "an greenworld GbR",
      input_name: "Name und Nachname*",
      input_email: "E-Mail*",
      input_phone: "Telefon",
      input_question: "Frage / Anfrage",
      btn_book: "Reservierung buchen",
      text_bottom: "Oder lassen Sie uns per Telefon oder E-Mail in Kontakt treten",
    },
  },
};
