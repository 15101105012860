import { en } from "vuetify/lib/locale";

export default {
  $vuetify: { ...en },
  ...en,
  home: {
    header: {
      btn_get_in_touch: "Get in touch",
    },
    first_component: {
      title1: {
        part1: "Welcome to",
        part2: "Green World AR GbR",
      },
      title2: "your reliable partner",
      title3: "for exclusive services.",
    },
    discover: {
      title: {
        part1: "Discover what",
        part2: "sets us apart",
      },
      contact_us_text: "Contact us for unforgettable experience",
      text_right:
        "We are ready to consult clients ensuring clear and personalised service for our diverse clientele. Let’s discuss it on:",
    },
    parallax: {
      title: "TOP NOTCH SERVICES",
      text: {
        part1: "Green World AR GbR offers first-class limousine service and",
        part2: "tailor-made private flights for discerning customers.",
      },
    },
    services: {
      title: "hi-end services",
      services: [
        {
          title: "Professional Chauffeur Services",
          description:
            "Whether you need transportation for corporate travel, special occasions, or simply a night out on the town, our chauffeurs ensure you arrive in style and comfort. Discreet, punctual, and attentive, our chauffeurs are committed to exceeding your expectations.",
        },
        {
          title: "Limousine Services",
          description:
            "Perfect for weddings, proms, corporate events, and other special occasions, our limousines are equipped with top-of-the-line features to ensure a lavish experience. From the moment you step into one of our elegant limousines, you'll be treated to unparalleled comfort and sophistication.",
        },
        {
          title: "Yachcharter Services",
          description:
            "Whether you're planning a romantic getaway, a family vacation, or a corporate retreat, our yachts are equipped with luxurious amenities and staffed by professional crews dedicated to your comfort and enjoyment.",
        },
        {
          title: "Private Flights",
          description:
            "For those who demand the highest level of exclusivity and convenience, our Private Flights service is the ideal solution. We offer access to a fleet of private jets and helicopters, ensuring you can travel swiftly and in absolute privacy.",
        },
        {
          title: "VIP Transfers",
          description:
            "Whether you're arriving at the airport, heading to a business meeting, or attending a special event, our VIP transfer service ensures a seamless journey. Our fleet of high-end vehicles and professional chauffeurs are dedicated to providing a first-class experience from start to finish.",
        },
      ],
      btn_request_info: "Request information",
    },
    languages: {
      title: "All services are available throughout all of Europe.",
      text_right:
        "We are ready to consult clients ensuring clear and personalised service for our diverse clientele. Let’s discuss it on:",
      we_speak_on: "Languages we work:",
    },
    products: {
      title: "Exceptional fleet",
      subtitle: "Exquisite fleet of luxury ground vehicles",
      text_right:
        "We take pride in offering an exclusive fleet of the most luxurious vehicles, ensuring that every journey with us is a statement of sophistication and elegance. Our services are available throughout all of Europe.",
      btn_see_all_cars: "See all cars",
      btn_see_green_worlds: "See Green Worlds’s entire fleet",
      vehicles: "vehicles",
    },
    approach: {
      title: "VIP Approach",
      subtitle: "Discover what sets us apart from others",
      text_right:
        "Our location is wherever our customers are. At Green World AR GbR, we offer our services with consistent quality, regardless of location. Our commitment to excellence and customer satisfaction knows no bounds. Learn more about our global services on our website.",
      contact_us_title:
        "Contact us now for unforgettable experiences with Green World AR GbR",
      btn_get_in_touch: "Get in touch",
      exclusive_services: {
        title: "Exclusive services",
        description:
          "Green World AR GbR offers first-class limousine service and tailor-made private flights for discerning customers.",
      },
      comfort_and_style: {
        title: "Comfort and style",
        description:
          "Our modern vehicles and luxurious flight solutions guarantee the highest level of comfort and style.",
      },
      experienced_team: {
        title: "Experienced team",
        description:
          "Our team consists of the two partners Adrian Kusari and Roman Pogreban as well as another 30 freelance employees who look after your well-being.",
      },
      discretion: {
        title: "Discretion",
        description: "We value discretion and privacy to meet your needs.",
      },
      commitment_to_excellence: {
        title: "Commitment to excellence",
        description:
          "Green World AR GbR offers first-class limousine service and tailor-made private flights for discerning customers.",
      },
    },
    navigation: {
      title: "NAVIgation",
      navigation_list: {
        service: {
          title: "Service",
          description: "Info about our services and fleet",
        },
        contact: {
          title: "Сontact",
          description: "Feel free for questions & VIP services requests",
        },
        home: {
          title: "HOME",
          description: "Greenworld homepage",
        },
      },
    },
  },
  service: {
    first_component: {
      title1: "We offer unique services that highlight",
      title2: "the status and comfort of our clients.",
      title3: "inspire travel and raise the bar for",
      title4: "service to a new level.",
    },
    all_services: {
      title: "All Services",
      left_block: {
        vip_service: {
          part1: "VIP",
          part2: "Service",
        },
        text: "Whether you need transportation for corporate travel, special occasions, or simply a night out on the town, our chauffeurs ensure you arrive in style and comfort. Discreet, punctual, and attentive, our chauffeurs are committed to exceeding your expectations.",
      },
      right_block: {
        title: "Chauffeur Services",
        items: {
          confidentiality: {
            title: "Confidentiality",
            text: "Your privacy is our top priority",
            btn_text: "Book a chauffeur now",
          },
          safety: {
            title: "Safety",
            text: "Our professional chauffeurs have many years of experience and background checks, which ensures your safety and timely arrival at your destination.",
            btn_text: "Book a chauffeur now",
          },
          luxury_vehicles: {
            title: "Luxury Vehicles",
            text: "Travel in style with top-tier cars such as Mercedes-Benz, Lamborghini and etc.",
            btn_text: "Book a chauffeur now",
          },
        },
      },
    },
    limousine_services: {
      left_block: {
        vip_service: {
          part1: "VIP",
          part2: "Service",
        },
        text: "Perfect for weddings, proms, corporate events, and other special occasions, our limousines are equipped with top-of-the-line features to ensure a lavish experience. From the moment you step into one of our elegant limousines, you'll be treated to unparalleled comfort and sophistication.",
      },
      right_block: {
        title: "Limousine Services",
        items: {
          topcars: {
            title: "Top Cars",
            text: "Check out our full range of executive class vehicles below.",
            btn_text: "Book a limousine",
          },
          chauffeurs: {
            title: "Chauffeurs",
            text: "Our professional chauffeurs have many years of experience and background checks, to ensure your safety and arrival at your destination in time.",
            btn_text: "Book a limousine",
          },
          pricing: {
            title: "Pricing",
            text: "Costs are calculated based on the tariff and vehicle selection you can see down below.",
            btn_text: "Book a limousine",
          },
        },
      },
    },
    yacht_charter: {
      left_block: {
        vip_service: {
          part1: "VIP",
          part2: "Service",
        },
        text: "Whether you're planning a romantic getaway, a family vacation, or a corporate retreat, our yachts are equipped with luxurious amenities and staffed by professional crews dedicated to your comfort and enjoyment.",
      },
      right_block: {
        title: "Yacht Charter",
        items: {
          team: {
            title: "Team",
            text: "As part of our Yacht Charter Services, we provide a professional crew including a captain, navigator, mechanic, steward, chef, dive master, deckhand, and onboard coordinator, ensuring an unparalleled level of service and comfort throughout your journey.",
            btn_text: "Request yacht charter",
          },
          pricing: {
            title: "Pricing",
            text: "Costs are calculated based on the number of guests, yacht model, duration of the charter, destination, and additional options.",
            btn_text: "Request yacht charter",
          },
          modern_yachts: {
            title: "Modern Yachts",
            text: "We provide well-maintained, modern yachts like Baglietto.",
            btn_text: "Request yacht charter",
          },
        },
      },
    },
    vip_jet: {
      left_block: {
        vip_service: {
          part1: "VIP",
          part2: "Service",
        },
        text: "For those who demand the highest level of exclusivity and convenience, our Private Flights service is the ideal solution. We offer access to a fleet of private jets and helicopters, ensuring you can travel swiftly and in absolute privacy.",
      },
      right_block: {
        title: "VIP Jet Charters",
        items: {
          privacy: {
            title: "Privacy",
            text: "A charter jet provides the opportunity to work or relax in your own private space.",
            btn_text: "Book private jet flight now",
          },
          convenience: {
            title: "Convenience",
            text: "Experience seamless travel tailored to your schedule.",
            btn_text: "Book private jet flight now",
          },
          no_queues: {
            title: "No Queues",
            text: "Skip the lines with access to private terminals.",
            btn_text: "Book private jet flight now",
          },
        },
      },
    },
    executive_transfers: {
      left_block: {
        vip_service: {
          part1: "VIP",
          part2: "Service",
        },
        text: "Whether you're arriving at the airport, heading to a business meeting, or attending a special event, our VIP transfer service ensures a seamless journey. Our fleet of high-end vehicles and professional chauffeurs are dedicated to providing a first-class experience from start to finish.",
      },
      right_block: {
        title: "Executive Transfers",
        items: {
          best_drivers: {
            title: "Best Drivers",
            text: "Our professional drivers ensure a smooth journey.",
            btn_text: "Book executive transfer now",
          },
          ample_space: {
            title: "Ample Space",
            text: "Enjoy the spacious interiors of our premium vehicles like Mercedes-Benz V-Class, S-Class, G-wag, BMW 520 and other.",
            btn_text: "Book executive transfer now",
          },
          punctuality: {
            title: "Punctuality",
            text: "We guarantee timely arrivals and departures.",
            btn_text: "Book executive transfer now",
          },
        },
      },
    },
    pricing: {
      title: "PRICING",
      gold_pack: {
        title: "GOLD Pack",
        description:
          "VIP transfer accompanied by 2 V-Classes and a luxury car. You will receive 3 chauffeurs.",
        price_type: "per month",
        plus_price_car: "Price for the specific car.",
        btn_request_now: "Request now",
      },
      silver_pack: {
        title: "Silver package",
        description:
          "VIP transfer accompanied by luxury car. You will receive 1 chauffeur.",
        price_type: "per month",
        plus_price_car: "Price for the specific car.",
        btn_request_now: "Request now",
      },
      bronze_pack: {
        title: "BRONZE PACKAGE",
        description:
          "VIP transfer accompanied by luxury car. You will receive 1 chauffeur.",
        price_type: "per month",
        plus_price_car: "Price for the specific car.",
        btn_request_now: "Request now",
      },
      individual_pack: {
        title: "INDIVIDUAL",
        description: "Will be calculated depending on the request.",
        btn_request_now: "Request now",
      },
    },
    private_jets: {
      title: "Yachts and Private jets on custom requests",
      text_right:
        "Greenworld GBR offers a fleet of luxurious yachts and private jets available for direct custom requests, ensuring unparalleled exclusivity and convenience for our discerning clients.",
    },
    green_team: {
      title: "Greenworld team",
      description:
        "Our team is led by partners Adrian Kusari and Roman Pogreban, who are dedicated to providing the best service possible. Along with our 30 employees, we ensure your well-being and satisfaction. We have more than 375 satisfied clients all over Europe, and we always strive to offer the highest quality service.",
      svg_subtitle: "DELIVERING UNMATCHED SERVICE TO ACROSS EUROPE",
    },
  },
  cards: {
    btn_book_it_now: "Book it now",
  },
  contact: {
    contact_us: {
      title: "СONTACT US",
      email: "EMAIL",
      phone: "phone",
      location: "LOCATION",
    },
  },
  footer: {
    title: "Green World AR GbR , Germany.",
    description: "Your reliable partner for exclusive services.",
    title_col_2: "Green World AR GbR , Germany.",
    btn_terms_and_conditions: "Terms and conditions",
    btn_privacy: "Privacy Policy",
    btn_cookies: "Cookies",
    btn_home: "Home",
    btn_service: "Service",
    btn_contact: "Contact",
  },
  modals: {
    contact_us: {
      title: "Personal reservation",
      subtitle: "to greenworld GbR",
      input_name: "Name and Surname*",
      input_email: "Email*",
      input_phone: "Telephone",
      input_question: "Question / Inquiry",
      btn_book: "Book reservation",
      text_bottom: "Or let’s get in touch via telephone or email"
    },
  },
};
