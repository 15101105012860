import { locale } from "../locales/index.js";
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import serviceView from "../views/Service.vue";
import contactView from "../views/Contact.vue";

const routes = [
  {
    path: "/:lang?",
    name: "home",
    component: Home,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/de/");
      }
    },
  },
  {
    path: "/:lang?/service/:categorie?",
    name: "service",
    component: serviceView,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/de/service");
      }
    },
  },
  {
    path: "/:lang?/contact",
    name: "contact",
    component: contactView,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/de/contact");
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    return new Promise((resolve) => {
      /* if (
        from.name != to.name &&
        to.name == "" &&
        from.name != undefined
      ) {
        setTimeout(() => {
          resolve({
            top: 0,
            el: "#ProductImages",
            behavior: "instant",
          });
        }, 250); */
      if (to.hash) {
        resolve({ top: 0, el: to.hash, behavior: "instant" });
      } else if (from.name != to.name && from.name != undefined) {
        resolve({ top: 0, behavior: "instant" });
      } else {
        resolve();
      }
    });
  },
});

export default router;
