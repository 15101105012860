<template>
  <v-img
    :src="$vuetify.display.xs ? require('../../assets/img/Home/cars_mobile.jpg') : require('../../assets/img/Home/cars.jpg')"
    :id="ImageAnim ? 'home_image_anim' : 'home_image'" width="100%" :min-height="height_image_paralax">
    <v-row justify="center" style="width: 100%; height: 100%;" class="ma-0">
      <v-col align-self="end" cols="12" sm="10" md="8">
        <v-row justify="space-between" class="mb-15">
          <v-col cols="1" align-self="center">
            <svg :width="$vuetify.display.smAndUp ? '15' : '10'" viewBox="0 0 15 28" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0H2.5V28H0V0Z" fill="#C0830A" />
              <path d="M0 15.2727V12.7273H15V15.2727H0Z" fill="#C0830A" />
            </svg>
          </v-col>
          <v-col cols="10" align-self="center" class="px-0">
            <div class="text-center text-title">
              <div class="row-text">
                <v-expand-transition class="py-1 py-sm-0 ">
                  <div v-show="textAnim.text1">
                    {{ t("$vuetify.home.first_component.title1.part1") }}
                    <span class="text-title-color">
                      {{ t("$vuetify.home.first_component.title1.part2") }}
                    </span>,
                  </div>
                </v-expand-transition>
              </div>
              <div class="row-text">
                <v-expand-transition class="py-1 py-sm-0">
                  <div v-show="textAnim.text2">
                    {{ t("$vuetify.home.first_component.title2") }}
                  </div>
                </v-expand-transition>
              </div>
              <div class="row-text">
                <v-expand-transition class="py-1 py-sm-0">
                  <div v-show="textAnim.text3">
                    {{ t("$vuetify.home.first_component.title3") }}
                  </div>
                </v-expand-transition>
              </div>
            </div>
          </v-col>
          <v-col cols="1" align-self="center">
            <svg :width="$vuetify.display.smAndUp ? '15' : '10'" viewBox="0 0 15 28" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M15 0H12.5V28H15V0Z" fill="#C0830A" />
              <path d="M15 15.2727V12.7273H0V15.2727H15Z" fill="#C0830A" />
            </svg>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: 'HelloPage',
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    ImageAnim: false,
    textAnim: {
      text1: false,
      text2: false,
      text3: false,
    },
  }),
  computed: {
    height_image_paralax() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "85vh";
        case "sm":
          return "75vh";
        case "md":
          return "75vh";
        case "lg":
          return "100vh";
        case "xl":
          return "75vh";
        case "xxl":
          return "75vh";
        default:
          return "100vh";
      }
    },
  },
  mounted() {
    if (document.readyState == "complete") {
      setTimeout(() => {
        this.ImageAnim = true;
      }, 0);
      setTimeout(() => {
        this.ImageAnim = true;
        this.textAnim.text1 = true;
      }, 800);
      setTimeout(() => {
        this.textAnim.text2 = true;
      }, 1300);
      setTimeout(() => {
        this.textAnim.text3 = true;
      }, 1800);
    } else {
      document.addEventListener(
        "PreloaderFinish",
        () => {
          setTimeout(() => {
            this.ImageAnim = true;
          }, 0);
          setTimeout(() => {
            this.ImageAnim = true;
            this.textAnim.text1 = true;
          }, 800);
          setTimeout(() => {
            this.textAnim.text2 = true;
          }, 1300);
          setTimeout(() => {
            this.textAnim.text3 = true;
          }, 1800);
        },
        false
      );
    }
  }
}
</script>
<style scoped>
.row-text {
  min-height: 25px;
}

.text-title {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 1.1rem;
  letter-spacing: 0.26rem;
  text-transform: uppercase;
}

.text-title-color {
  background: -webkit-linear-gradient(320deg, #BD7810, #FE4F32);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

@media (max-width: 600px) {
  .text-title {
    font-size: 0.7rem;
    letter-spacing: 0.2rem;
  }
}
</style>

<style>
#home_image {
  background-color: rgb(16, 16, 16);
}

#home_image .v-img__img {
  opacity: 0;
}

#home_image_anim {
  background-color: rgb(16, 16, 16);
}

#home_image_anim .v-img__img {
  opacity: 0;
  animation-name: home_image_anim;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
  animation-direction: normal;
  animation-timing-function: linear;
}

@keyframes home_image_anim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>