<template>
    <v-container fluid class="container-main">
        <v-container fluid class="pa-0">
            <v-row justify="center">
                <v-col cols="12" md="11">

                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
import { useLocale } from "vuetify";

export default {
    name: 'GetInTouchPage',
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
}
</script>
<style scoped>
.iframe-map {
    border-radius: 25px;
}

.options-description {
    color: #9A9A9B;
    font-size: 1.4rem;
    text-decoration: none;
    transition: .3s;
}

.options-description:hover {
    color: #fff;
}

.options-description-svg:hover path {
    fill: #fff;
}

.options-title {
    color: #E6E8EB;
    font-size: 3.5rem;
    font-weight: 400;
    text-transform: uppercase;
}

.container-main {
    min-width: 100vw;
    background: #101010;
    padding-top: 10vh;
    padding-bottom: 10vh;
    position: relative;
    z-index: 1;
    margin-top: -1px;
    border-radius: 0px 0px 25px 25px;
    box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.25);
}

.svg-animation {
    overflow: visible;
    transform-origin: 50% 50%;
    animation-name: svg-anim;
    animation-duration: 18s;
    animation-direction: normal;
    animation-timing-function: linear;
    transform: rotate(0deg);
    animation-iteration-count: infinite;
}

@keyframes svg-anim {
    from {
        transform: rotate(-360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

.text-title {
    color: #E6E8EB;
    font-weight: 300;
    font-size: 5.4rem;
    line-height: 7rem;
    text-align: left;
    text-transform: uppercase;
}


@media (min-width: 600px) and (max-width: 960px) {
    .text-title {
        font-size: 3.8rem;
        font-weight: 300;
        line-height: 5rem;
    }

}

@media (max-width: 600px) {
    .text-title {
        font-size: 2.1rem;
        font-weight: 400;
        line-height: 3rem;
    }
}
</style>